import type { ApolloError } from '@apollo/client';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { ActorSwitchType } from '@xing-com/actor-switch';
import type { SelectableAudienceType } from '@xing-com/audience-selection/lib/types';
import { OmView } from '@xing-com/platform-layout-om';
import type { PollDataType, PollPreviewType } from '@xing-com/poll';
import { EMPTY_POLL_DATA, PollForm } from '@xing-com/poll';

import { CommboxContext } from '../../contexts/commbox-context';
import { CommboxPermissionsContextProvider } from '../../contexts/commbox-permission-context';
import { trackAction } from '../../tracking';
import { CommboxView } from '../../types';
import type {
  CommboxApplicationType,
  CommboxAttachmentType,
  CommboxFeaturePermissionsType,
} from '../../types';
import { CommboxForm } from '../commbox-form/commbox-form';
import { CommboxSuccessBanner } from '../commbox-success-banner/commbox-success-banner';
import type { CommboxSuccessBannerType } from '../commbox-success-banner/commbox-success-banner';
import { DeleteDialogCommbox } from '../delete-dialog-commbox/delete-dialog-commbox';

export type CommboxProps = {
  children: any;

  // Crate & EP
  actors: ActorSwitchType['actors'];
  application: CommboxApplicationType;
  featurePermissions: CommboxFeaturePermissionsType;
  globalId: string;
  isAllowedToPost: boolean;
  isCreatingPosting: boolean;
  isUploadModalTriggered: boolean;
  postMutation: (data: any) => Promise<{
    error?: any;
    success?: any;
  }>;
  posting?: any;
  selectableAudiences: SelectableAudienceType[];
  successMessage: CommboxSuccessBannerType;
  setIsAllowedPost: Dispatch<SetStateAction<boolean>>;

  // Crate
  crateImageUploader?: (file: File) => Promise<string | void | null>;

  // EP
  createPostingError?: ApolloError;
  isVideoUploadOpen?: boolean;
  pageId?: string; // same as globalId
  tagManagerId?: string;
  setIsVideoUploadOpen?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  onOpenActorSwitch?: () => void;
  onOpenAudienceSelection?: () => void;
  onSelectAudience?: (id: SelectableAudienceType['id']) => void;
};
export const Commbox: FC<CommboxProps> = ({
  actors,
  application,
  children,
  createPostingError,
  featurePermissions,
  globalId,
  isAllowedToPost,
  isCreatingPosting,
  isUploadModalTriggered,
  isVideoUploadOpen = false,
  postMutation,
  pageId = '',
  posting,
  selectableAudiences,
  successMessage,
  tagManagerId,
  onSelectAudience = () => undefined,
  setIsAllowedPost,
  setIsVideoUploadOpen = () => undefined,
  crateImageUploader,
  onClose = () => undefined,
  onOpenActorSwitch = () => undefined,
  onOpenAudienceSelection = () => undefined,
}) => {
  const closeOmView = useRef<any>();

  const [attachmentType, setAttachmentType] =
    useState<CommboxAttachmentType>('EMPTY');
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [commboxHasContent, setCommboxHasContent] = useState(false);
  const [actorType, setActorType] = useState('');
  const [shouldOpenFilePicker, setShouldOpenFilePicker] = useState(
    isUploadModalTriggered
  );
  const [currentView, setCurrentView] = useState<CommboxView>(
    CommboxView.COMMBOX
  );
  const [pollData, setPollData] = useState<
    PollDataType | PollPreviewType | null
  >(null);

  const closeDialog = () => {
    closeOmView.current?.();
    setShowCloseDialog(false);
    onClose?.();
    setCommboxHasContent(false);
    setShouldOpenFilePicker(false);
    setAttachmentType('EMPTY');
  };

  const handleInterceptClose = () => {
    setShowCloseDialog(true);
    if (commboxHasContent) {
      const PropTrackAction = 'social_share_commbox_cancel_dialog_open';
      trackAction({ PropTrackAction });
    }
  };

  useEffect(() => {
    if (isUploadModalTriggered) setShouldOpenFilePicker(true);
  }, [isUploadModalTriggered]);

  useEffect(() => {
    return () => closeOmView.current?.();
  }, []);

  return (
    <>
      <OmView
        trigger={(activateOmView) => children(activateOmView)}
        interceptClose={handleInterceptClose}
      >
        {({ handleClose }) => {
          closeOmView.current = handleClose;

          return (
            <CommboxContext.Provider
              value={{
                pageId,
                actors,
                application,
                attachmentType,
                featurePermissions,
                createPostingError,
                globalId,
                isUploadModalTriggered,
                isVideoUploadOpen,
                successMessage,
                posting,
                selectableAudiences,
                shouldOpenFilePicker,
                tagManagerId,
                currentView,
                pollData,
                isAllowedToPost,
                isCreatingPosting,
                actorType,
                commboxHasContent,
                postMutation,
                onClose,
                crateImageUploader,
                onOpenActorSwitch,
                onOpenAudienceSelection,
                onSelectAudience,
                onSuccess: () => closeDialog(),
                setCurrentView,
                setPollData,
                setActorType,
                setAttachmentType,
                setCommboxHasContent,
                setIsAllowedPost,
                setIsVideoUploadOpen,
                setShouldOpenFilePicker,
                closeDialog,
              }}
            >
              <CommboxPermissionsContextProvider>
                <>
                  <DeleteDialogCommbox
                    setShowCloseDialog={setShowCloseDialog}
                    showCloseDialog={showCloseDialog}
                  />
                  <CommboxForm />
                  {currentView === 'poll-form' && (
                    <PollForm
                      {...(pollData?.openDays ? pollData : EMPTY_POLL_DATA)}
                      onClose={() => {
                        setCurrentView(CommboxView.COMMBOX);
                      }}
                      onDone={(newPollData) => {
                        setAttachmentType('POLLS');
                        setPollData(newPollData);
                      }}
                    />
                  )}
                </>
              </CommboxPermissionsContextProvider>
            </CommboxContext.Provider>
          );
        }}
      </OmView>
      <CommboxSuccessBanner {...successMessage} />
    </>
  );
};
