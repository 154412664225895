import { useMutation } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import type { FC } from 'react';

import type {
  PostingsVisibility,
  EntityPageFocusType,
} from '@xing-com/crate-common-graphql-types';
import {
  EntityPageContractType,
  PostingPublicationStrategy,
} from '@xing-com/crate-common-graphql-types';
import type { CommboxProps } from '@xing-com/crate-entity-pages-news-commbox';
import {
  ATTACHMENT_TYPES,
  Commbox,
  COMMBOX_ACTOR_TYPES,
} from '@xing-com/crate-entity-pages-news-commbox';

import { COMMBOX_SELECTABLE_AUDIENCES } from '../../config/commbox';
import { CreateTextPostingDocument } from '../../graphql/mutations/create-posting-mutation.gql-types';
import type { CreateTextPostingMutationVariables } from '../../graphql/mutations/create-posting-mutation.gql-types';
import { useEditContext } from '../../hooks/use-edit-context/use-edit-context';
import { usePageContext } from '../../hooks/use-page-context/use-page-context';
import {
  trackCommboxAudienceSelect,
  trackCommboxOpenActorSwitch,
  trackCommboxOpenAudienceSelection,
} from '../../tracking';
import { mapAudienceTrackingValue } from '../../utils/audience';

const COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT = 7000;

type CreatePostButtonProps = {
  children: (onClick: () => void) => JSX.Element;
  defaultPostVisibility?: PostingsVisibility;
  onCompleted?: () => void;
  onClose?: () => void;
  setLazyLoadingIsAllowed?: (allowed: boolean) => void;
  page?: 'entity_pages/overview' | 'entity_pages/subpage/news';
};

export const CreatePostButton: FC<CreatePostButtonProps> = ({
  children,
  defaultPostVisibility,
  onCompleted = () => undefined,
  onClose = () => undefined,
  setLazyLoadingIsAllowed,
  page,
}) => {
  const { isEditing } = useEditContext();
  const { pageContext } = usePageContext();

  const [isUploadModalTriggered, setUploadModalTrigger] = useState(false);
  const [postWasSent, setPostWasSent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isAllowedToPost, setIsAllowedPost] = useState(true);
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  const [attachmentType, setAttachmentType] = useState('EMPTY');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [successPostHeadLine, setSuccessPostHeadLine] = useState(
    'COMMBOX_SUCCESS_HEADLINE'
  );
  const [successPostHeadLineValues, setSuccessPostHeadLineValues] = useState(
    {}
  );
  const [successPostDescription, setSuccessPostDescription] = useState(
    'COMMBOX_SUCCESS_DESC'
  );
  const [successPostDescriptionValues, setSuccessPostDescriptionValues] =
    useState({});

  const canEditLinkPreview = pageContext.isPublisherPage && isEditing;

  const isPaidPage =
    pageContext.contractType === EntityPageContractType.PaidPlus ||
    pageContext.contractType === EntityPageContractType.Paid;
  const isNewsPage = (type: EntityPageFocusType) =>
    type === 'TOPIC_PAGE' || type === 'PUBLISHER' || type === 'INDUSTRY_PAGE';

  const [
    postingsCreatePosting,
    { loading: isCreatingPosting, error: createPostingError },
  ] = useMutation(CreateTextPostingDocument, {
    onCompleted: (data) => {
      if (data.postingsCreatePosting && !data.postingsCreatePosting.error) {
        setShowSuccessMessage(true);
        setPostWasSent(true);
        if (setLazyLoadingIsAllowed) setLazyLoadingIsAllowed(true);
        onCompleted();
        return data;
      }
      return null;
    },
    onError: () => setIsAllowedPost(true),
  });

  const setBannerWhenScheduling = (publishAt?: string | null) => {
    let successMessageValues = {};
    let successMessage = 'COMMBOX_SUCCESS_DESC';
    if (publishAt) {
      setSuccessPostHeadLine('COMMBOX_SUCCESS_HEADLINE');
      setSuccessPostHeadLineValues({});
      successMessage = 'COMMBOX_SUCCESS_SCHEDULED_DESC';
      const publishedDate = new Date(publishAt);
      successMessageValues = {
        date: `${publishedDate.getDate()}/${
          publishedDate.getMonth() + 1
        }/${publishedDate.getFullYear()}`,
        time: `${publishedDate.getHours()}:${
          publishedDate.getMinutes() < 10
            ? `0${publishedDate.getMinutes()}`
            : publishedDate.getMinutes()
        }`,
      };
      setSuccessPostDescription(successMessage);
      setSuccessPostDescriptionValues(successMessageValues);
    }
  };

  const createPosting = async ({
    comment,
    commentArticleV1,
    actorGlobalId,
    images = null,
    links = null,
    publishAt,
    publicationStrategy = PostingPublicationStrategy.Now,
    videos = null,
    visibility = defaultPostVisibility,
  }: CreateTextPostingMutationVariables) => {
    setBannerWhenScheduling(publishAt);

    const { data } = await postingsCreatePosting({
      variables: {
        actorGlobalId,
        comment: comment && comment.length ? comment : '',
        commentArticleV1,
        images,
        links,
        publishAt,
        publicationStrategy,
        videos,
        visibility,
      },
    });

    return {
      error: data?.postingsCreatePosting?.error,
      success: data?.postingsCreatePosting?.success,
    };
  };

  useEffect(() => {
    if (postWasSent) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessPostDescription('COMMBOX_SUCCESS_DESC');
        setSuccessPostDescriptionValues({});
        timeoutRef.current = null;
      }, COMMBOX_SUCESS_DIALOG_VISIBILITY_TIMEOUT);
      setPostWasSent(false);
    }
  }, [postWasSent]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const commboxSharedProps: CommboxProps = {
    attachmentType,
    canEditLinkPreview,
    globalId: pageContext.globalId as string,
    isAllowedToPost,
    isUploadModalTriggered,
    isVideoUploadOpen,
    setAttachmentType,
    setIsAllowedPost,
    setIsVideoUploadOpen,
    setShowSuccessMessage,
    setUploadModalTrigger,
    showSuccessMessage,
    successPostDescription,
    successPostDescriptionValues,
    successPostHeadLine,
    successPostHeadLineValues,
    application: 'ENTITY_PAGES',
    onOpenAudienceSelection: () => trackCommboxOpenAudienceSelection(),
    onOpenActorSwitch: () => trackCommboxOpenActorSwitch(),
    // @ts-expect-error Commbox will be removed in Q2
    onSelectAudience: (id: 'PUBLIC' | 'PRIVATE') =>
      trackCommboxAudienceSelect(mapAudienceTrackingValue(id)),
    onClose: () => {
      onClose();
      setIsVideoUploadOpen(false);
    },
    pageId: pageContext.globalId,
    actors: [
      {
        logo: pageContext.logo as string,
        actorName: pageContext.pageTitle,
        globalId: pageContext.globalId as string,
        type: COMMBOX_ACTOR_TYPES.PAGE,
        // @ts-expect-error TODO: fix this type
        features: [ATTACHMENT_TYPES.IMAGE, ATTACHMENT_TYPES.VIDEO],
      },
    ],
    // @ts-expect-error Commbox will be removed in Q2
    selectableAudiences: COMMBOX_SELECTABLE_AUDIENCES,
    successMessage: {
      description: successPostDescription,
      descriptionValues: successPostDescriptionValues,
      headline: successPostHeadLine,
      headlineValues: successPostHeadLineValues,
      setShowSuccessMessage,
      showSuccessMessage,
    },
    featurePermissions: {
      enableMentions: true,
      enableVideo: true,
      enableScheduling: isPaidPage,
      enablePolls: false,
      enableMultiImage: false,
      enableSchedulingLock:
        Boolean(pageContext.companyId) ||
        isNewsPage(pageContext.focusType as EntityPageFocusType),
    },
  };

  return (
    <Commbox
      {...commboxSharedProps}
      tagManagerId="btn_postnews"
      createPostingError={createPostingError}
      isCreatingPosting={isCreatingPosting}
      postMutation={createPosting}
      pageId={
        page ??
        (pageContext.moduleTitle
          ? 'entity_pages/subpage/news'
          : 'entity_pages/overview')
      }
    >
      {children}
    </Commbox>
  );
};
